import {
  Box,
  Button,
  Grid,
  Modal,
  Tooltip,
  Tabs,
  Tab,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import moment from "moment";
import { Form } from "@unform/web";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AutoComplete from "../../../../components/Form/AutoComplete";
import Checkbox from "../../../../components/Form/Checkbox";
import DatePicker from "../../../../components/Form/DatePicker";
import Input from "../../../../components/Form/Input";
import Select from "../../../../components/Form/Select";
import ReactSelect from "../../../../components/Form/ReactSelect";
import CreateAllocationSchema from "../../../../schemas/CreateAllocationSchema";
import AdminTitleHeader from "../../../components/AdminTitleHeader/AdminTitleHeader";
import HorizontalMenu from "../../../components/HorizonalMenu/HorizontalMenu";
import removeIconWhite from "../../../../assets/img/icons/deleteWhite.svg";
import uploadIcon from "../../../../assets/img/icons/upload.svg";
import downIcon from "../../../../assets/img/icons/downIcon.svg";
import useAlocacaoQuadros from "../../../hooks/useAlocacaoQuadros";
import UpdateAllocationSchema from "../../../../schemas/UpdateAllocationSchema";
import styles from "./alocacaoStyles.module.css";
import SideTable from "./SideTable";
import SearchBar from "./SearchBar";
import * as Yup from "yup";
import { styled } from "@material-ui/styles";
import KeycloakService from "../../../../services/KeycloakService";
import axios from "axios";

const ModalContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: "#fff",
  margin: "20vh 50vh",
  borderRadius: "6px",
  // [theme.breakpoints.up("xxl")]: {
  //   height: "825px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("xxl")]: {
  //   height: "775px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("xl")]: {
  //   height: "675px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("lg")]: {
  //   height: "605px",
  // margin: "20vh 50vh",

  // },
}));

const AlocacaoQuadrosForm = ({ match }) => {
  const visualizeOnly = match.params.operation === "ver";
  const formRef = useRef();
  const theme = useTheme();
  const history = useHistory();

  console.log("Operation type: ", match.params.operation);

  // const [file, setFile] = useState({})
  // const [fileName, setFileName] = useState("")

  const [action, setAction] = useState("add");
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState("");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [allocationInfo, setAllocationInfo] = useState({});
  const [horizontalMenuOption, setHorizontalMenuOption] =
    useState("alocacao_quadros");

  const [orgaoRendered, setOrgaoRendered] = useState(false);
  const [inactivePageIndex, setInactivePageIndex] = useState(0);

  const {
    unit,
    institutionEmployees,
    currentEmployee,
    fieldsMetaData,
    fixDate,
    fixAutoComplete,
    onEmployeeClicked,
    onAddValidationComplete,
    onEditValidationComplete,
    id,
    setSideTablePageIndex,
    sideTablePageIndex,
    institutionEmployeesTotalPages,
    query,
    setQuery,
    inactiveQuery,
    setInactiveQuery,
    getResourceMobilityByEmployeeId,
    getResourceMobilityInfoByEmployeeId,
    clickedEmployee,
    inactiveEmployees,
  } = useAlocacaoQuadros(
    match.params.institutionId,
    match.params.unitId,
    match.params.employeeId,
    match.params.operation
  );

  const [userInfo, setUserInfo] = useState();

  const getUserInfo = async () => {
    const response = await KeycloakService.getUserInfo();
    const { preferred_username } = response;
    setUserInfo(preferred_username);
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [isInputRendered, setIsInputRendered] = useState(false);
  const [allocationItems, setAllocationItems] = useState({
    item: "",
    status: "",
  });

  useEffect(() => {
    if (
      match.params.employeeId &&
      (match.params.operation === "editar" ||
        match.params.operation === "mobilidade" ||
        match.params.operation === "progresso")
    ) {
      setAction("edit");
    } else {
      setAction("add");
    }

    if (!userInfo) {
      getUserInfo();
    }
  }, [match.params, userInfo]);

  const boxStyles = useMemo(() => {
    if (match.params.operation === "mobilidade") {
      return { display: "flex", flexFlow: "column", gap: "1rem" };
    }
    return {};
  }, [match.params.operation]);

  const horizontalButtons = [
    {
      code: "alocacao_quadros",
      label: "Alocação dos Quadros",
      onClick: () => setHorizontalMenuOption("alocacao_quadros"),
    },
  ];

  const handleFile = (e) => {
    let files = e.target.files[0];
    setFile({ file: files });
    setFileName(e.target.files[0].name);
  };
  const handleRemoveDoc = () => {
    setFile({});
    setFileName("");
  };

  const sideTableHeaders = [
    {
      field: "name",
      label: "Quadro",
    },
    {
      field: "bi",
      label: "BI",
    },
  ];

  const addAllocationValidation = (withFixedValues) => {
    console.log("withFixedValues - ", withFixedValues);
    let employeeId;
    if (!id) {
      const employee = institutionEmployees.find(
        (employee) => employee.bi === withFixedValues.employee.bi
      );

      employeeId = employee?.id;
    } else {
      employeeId = id;
    }

    var values = withFixedValues;
    // if (withFixedValues.category?.name?.length === 0) {
    //   values.category.name = values.category.id;
    // }

    // if (withFixedValues.category?.id === undefined) {
    //   values.category.name = "";
    // }

    CreateAllocationSchema.validate(
      {
        ...values,
        employeeId: employeeId,
        unit: { name: match.params.unitId },
      },
      {
        abortEarly: false,
      }
    )
      .then((submitData) => {
        onAddValidationComplete({
          document: file ?? null,
          allocationLeaveTypeRequest: submitData.allocationLeaveTypeRequest,
          startOfLeave: submitData.startOfLeave,
          endOfLeave: submitData.endOfLeave,
          agentNumber: submitData.agentNumber,
          ssNumber: submitData.ssNumber,
          cifNumber: submitData.cifNumber,
          consultant: submitData.consultant,
          startedOn: submitData.startedOn,
          endedOn: submitData.endedOn,
          unitId: match.params.unitId,
          employeeId: submitData.employeeId,
          changeOfJuridicalBondId: submitData.changeOfJuridicalBond.id,
          contractualRegimenId: submitData.contractualRegimen.id,
          juridicalBondId: submitData.juridicalBond.id,
          positionId: submitData.position.id,
          categoryId: submitData.categoryId,
          institutionOfOriginId: submitData.institutionOfOrigin.id,
          showNotification: true,
          onSuccessMessage: "Quadro alocado com sucesso",
        });
        formRef.current.setErrors({});
      })
      .catch((error) => {
        console.log("schema is not valid:");
        if (error instanceof Yup.ValidationError) {
          const errorMessages = {};
          error.inner.forEach((error) => {
            console.log(error.message);
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      });
  };

  console.log("INACTIVE QUERY: ", inactiveQuery);
  const editAllocationValidation = (withFixedValues) => {
    var values = withFixedValues;

    UpdateAllocationSchema.validate(
      {
        ...values,
        employeeId: match.params.employeeId,
        unit: { name: match.params.unitId },
        id: currentEmployee.id,
      },
      {
        abortEarly: false,
      }
    )
      .then((submitData) => {
        console.log("submitData - ", submitData);
        onEditValidationComplete({
          id: currentEmployee.id,
          agentNumber: submitData.agentNumber,
          cifNumber: submitData.cifNumber,
          consultant: submitData.consultant,
          startedOn: submitData.startedOn,
          endedOn: submitData.endedOn,
          unitId: match.params.unitId,
          employeeId: submitData.employeeId,
          changeOfJuridicalBondId: submitData.changeOfJuridicalBond.id,
          contractualRegimenId: submitData.contractualRegimen.id,
          juridicalBondId: submitData.juridicalBond.id,
          positionId: submitData.position.id,
          categoryId: submitData.categoryId,
          ssNumber: submitData.ssNumber,
          institutionOfOriginId: submitData.institutionOfOrigin.id,
          //showNotification: true,
          //onSuccessMessage: "Alocação editada com sucesso",
          responsavel: userInfo,
          historicTypeId: 1,
        });
        formRef.current.setErrors({});
      })
      .catch((error) => {
        console.log("schema is not valid:");
        if (error instanceof Yup.ValidationError) {
          const errorMessages = {};
          error.inner.forEach((error) => {
            console.log(error.path, error.message);
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      });
  };

  const handleEmployeeAllocationSubmit = (formData, { reset }) => {
    if (visualizeOnly) return;
    var withFixedValues = fixDate(fieldsMetaData, formData);
    withFixedValues = fixAutoComplete(fieldsMetaData, formData);

    if (action === "add") {
      console.log("-----------ADD---------", formData)
      addAllocationValidation(withFixedValues);
    }
    if (action === "edit") {
      editAllocationValidation(withFixedValues);
    }
  };

  const margin = history.location.pathname.includes("admin") ? "" : "4rem 7rem";

  const ellipsisText = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
  };

  // const handleFile = (e) => {
  //   // console.log("EEEE - ", e.target.files[0])
  //   let files = e.target.files[0]
  //   setFile({"file": files})
  //   setFileName(e.target.files[0].name)
  // }

  const onRowClicked = (item, status) => {
    setAllocationItems({
      item: item,
      status: status,
    });
    if (status === "ACTIVE") {
      getResourceMobilityInfoByEmployeeId(item.id);
      return setShowInfoModal(true);
    }
    onEmployeeClicked(item);
  };

  const onAllocatedRowClicked = (item) => {
    onEmployeeClicked(item);
    return setShowInfoModal(false);
  };

  let minYearConverted;

  const splited = currentEmployee?.startedOn?.split("-");
  minYearConverted = splited?.[2] + "-" + splited?.[1] + "-" + splited?.[0];

  // let institutionEmployeesActive = [];
  // let institutionEmployeesInactive = [];

  const [totalPageActive, setTotalPageActive] = useState(0);
  const [totalPageInactive, setTotalPageInactive] = useState(0);

  const [institutionEmployeesActive, setInstitutionEmployeesActive] = useState(
    []
  );
  const [institutionEmployeesInactive, setInstitutionEmployeesInactive] =
    useState([]);

  console.log("Current employee: ", currentEmployee);
  console.log("Current action: ", action);

  console.log("HOW DATA COME: ", institutionEmployees);

  console.log("SEE TOLTA PA: ", institutionEmployeesTotalPages);

  useEffect(() => {
    setInstitutionEmployeesActive(institutionEmployees);
    setTotalPageActive(institutionEmployees.length);
  }, [institutionEmployees, institutionEmployees.length]);

  useEffect(() => {
    setInstitutionEmployeesInactive(inactiveEmployees);
  }, [inactiveEmployees]);
  /*  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/resource-mobility/to-assign-inative/${match.params.institutionId}?page=${inactivePageIndex}&size=20`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        setInstitutionEmployeesInactive(response.data.content);
        setTotalPageInactive(response.data.totalPages); // Total de páginas para quadros inativos
      });
  }, [match.params.institutionId, inactivePageIndex]); */

  return (
    <div style={{ display: "flex", flexDirection: "column", margin }}>
      <AdminTitleHeader
        title={
          match.params.operation === "mobilidade"
            ? "Mobilidade de Quadros"
            : "Editar Alocação de Quadros"
        }
      />
      <Box
        sx={{
          backgroundColor: "#fff",
          border: "1px solid #dfdfdf",
          borderBottom: "2px solid #dfdfdf",
          padding: "1rem 3rem",
          display: "flex",
        }}
      >
        <Box sx={{ width: "50%", display: "flex", gap: "2rem" }}>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "flex-end",
              color: theme.palette.primary.dark,
              fontWeight: "bold",
            }}
          >
            <span>Entidade</span>
            <span>Data de constituição</span>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "flex-start",
              color: "#3B4144",
            }}
          >
            <Tooltip title={unit?.institution?.name} placement="top">
              <span style={ellipsisText}>{unit?.institution?.name}</span>
            </Tooltip>
            <Tooltip
              title={unit?.institution?.constitutionDate}
              placement="top-start"
            >
              <span style={ellipsisText}>
                {moment(unit?.institution?.constitutionDate).format(
                  "DD-MM-yyyy"
                )}
              </span>
            </Tooltip>
          </Box>
        </Box>

        <Box sx={{ width: "50%", display: "flex", gap: "2rem" }}>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "flex-end",
              color: theme.palette.primary.dark,
              fontWeight: "bold",
            }}
          >
            <span>Orgão</span>
            <span>Pelouro</span>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "flex-start",
              color: "#3B4144",
            }}
          >
            <Tooltip title={unit?.name} placement="top">
              <span style={ellipsisText}>{unit?.name}</span>
            </Tooltip>
            <Tooltip title={unit?.expertiseName} placement="top">
              <span style={ellipsisText}>{unit?.expertiseName}</span>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <HorizontalMenu
        currentOption={horizontalMenuOption}
        options={horizontalButtons}
      />
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "2rem 1.5rem",
          display: "flex",
        }}
      >
        <Box
          sx={{
            flexBasis: `${
              ["ver", "editar", "mobilidade", "progresso"].includes(
                match.params.operation
              )
                ? "100%"
                : "65%"
            }`,
            borderRight: "1px solid #cecece",
          }}
        >
          <Form
            ref={formRef}
            onSubmit={handleEmployeeAllocationSubmit}
            initialData={currentEmployee}
            style={{ borderRadius: "unset", boxShadow: "none", padding: "0" }}
          >
            <Box sx={{ display: "flex", flexFlow: "row wrap", gap: "1rem" }}>
              {fieldsMetaData.map((meta, i) => {
                return meta.field === "endedOn" ? (
                  action === "edit" && (
                    <Box sx={meta.size}>
                      <DatePicker
                        key={i}
                        type={meta.type}
                        name={meta.field}
                        label={meta.label}
                        disabled={meta.disabled}
                        withHours={meta.withHours}
                        dateFormat={meta.dateFormat}
                        placeholder={meta.placeholder}
                        isMandatory={meta.isMandatory}
                        style={{
                          margin: "0",
                        }}
                        minYears={moment(
                          unit?.institution?.constitutionDate
                        ).format("DD-MM-yyyy")}
                      />
                    </Box>
                  )
                ) : meta?.title ? (
                  <Typography
                    variant="h4"
                    color="primary"
                    gutterBottom
                    sx={{
                      width: "100%",
                      fontWeight: "bold",
                    }}
                  >
                    {match.params.operation == "mobilidade" &&
                    meta.title != "Licença"
                      ? meta.title
                      : match.params.operation != "mobilidade" && meta.title
                      ? meta.title
                      : null}
                  </Typography>
                ) : (
                  <Box
                    sx={
                      match.params.operation == "mobilidade"
                        ? boxStyles
                        : meta.size
                    }
                  >
                    {![
                      "select",
                      "checkbox",
                      "none",
                      "date",
                      "file",
                      "auto-complete",
                      "entity",
                    ].includes(meta.type) && (
                      <Input
                        key={i}
                        type={meta.type}
                        name={meta.field}
                        label={meta.label}
                        onChange={meta.onChange}
                        disabled={meta.disabled}
                        isMandatory={meta.isMandatory}
                      />
                    )}

                    {meta.type == "auto-complete" && (
                      <AutoComplete
                        showModal={true}
                        Select={Select}
                        key={i}
                        type={meta.type}
                        split={meta.split}
                        name={meta.field}
                        label={meta.label}
                        disabled={meta.disabled}
                        optionscode={meta.optionscode}
                        splitOptionsCodes={meta.splitOptionsCodes}
                        placeholder={meta.placeholder}
                        isMandatory={meta.isMandatory}
                      />
                    )}

                    {match.params.operation == "mobilidade" &&
                    (meta.field === "startOfLeave" ||
                      meta.field === "endOfLeave")
                      ? null
                      : meta.type == "date" && (
                          <DatePicker
                            key={i}
                            type={meta.type}
                            name={meta.field}
                            label={meta.label}
                            disabled={meta.disabled}
                            withHours={meta.withHours}
                            dateFormat={meta.dateFormat}
                            placeholder={meta.placeholder}
                            isMandatory={meta.isMandatory}
                            notEndDate={
                              meta.field == "endOfLeave" ? true : false
                            }
                            minYears={moment(
                              unit?.institution?.constitutionDate
                            ).format("DD-MM-yyyy")}
                            style={{ margin: "0 !important" }}
                          />
                        )}

                    {match.params.operation != "mobilidade"
                      ? meta.type == "select" && (
                          <Select
                            key={i}
                            name={meta.field}
                            label={meta.label}
                            options={meta.options}
                            onChange={meta.onChange}
                            disabled={meta.disabled}
                            isMandatory={meta.isMandatory}
                          />
                        )
                      : null}

                    {match.params.operation != "mobilidade"
                      ? meta.type == "entity" && (
                          <ReactSelect
                            key={i}
                            name={meta.field}
                            label={meta.label}
                            options={meta.options}
                            onChange={meta.onChange}
                            disabled={meta.disabled}
                            isMandatory={meta.isMandatory}
                          />
                        )
                      : null}

                    {match.params.operation != "mobilidade"
                      ? meta.type == "checkbox" && (
                          <Checkbox
                            key={i}
                            name={meta.field}
                            label={meta.label}
                            disabled={meta.disabled}
                            isMandatory={meta.isMandatory}
                            styles={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                            }}
                            style={{
                              marginLeft: "-25px",
                              width: "15px",
                              height: "15px",
                            }}
                          />
                        )
                      : null}

                    {match.params.operation != "mobilidade"
                      ? meta.type == "file" && (
                          // <div className={styles.labels}>
                          // <label className={styles.w20} htmlFor="files"> Anexos </label>
                          // <div className={styles.w80}>
                          // <div
                          // // style={{ gridTemplateColumns: `${institutionData?.documentDecree ? '50% 25% 25%' : '75% 25%' }` }}
                          // className={styles.uploads}>
                          //   <div className={styles.textEllips}>
                          //     <label>
                          //       {/* <span>
                          //         {fileName ? fileName : institutionData?.documentDecree?.name ?
                          //         <span>
                          //           {institutionData?.documentDecree?.name}
                          //         </span> : "Upload Files"}
                          //       </span> */}
                          //     </label>
                          //   </div>
                          // {/* { institutionData?.documentDecree &&
                          // <div
                          // // onClick={ () => openFile(institutionData?.documentDecree?.id)}
                          // style={{ backgroundColor: "#fff", border: "2px solid #2b404e", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "4px"  }}>
                          //   <img style={{ marginRight: "5px", cursor: "pointer"}} src={downIcon} />
                          //   <span style={{ color: "#2b404e", fontSize: "0.7rem" }}>  {"Download"} </span>
                          // </div>
                          // } */}

                          // <div style={{ marginLeft: "-6px", backgroundColor: "#2b404e", width: "100%", borderRadius: "4px" }}>
                          //   <label htmlFor="filesType">
                          //     { !fileName ?
                          //     <div style={{ cursor: "pointer" ,display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", borderRadius: "20px", padding: "2px" }}>
                          //       <img style={{ marginRight: "5px", cursor: "pointer"}} src={uploadIcon} />
                          //       <span> Upload </span>
                          //     </div>
                          //       :
                          //     <div style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", borderRadius: "20px", padding: "2px" }}>
                          //       <img onClick={ handleRemoveDoc } style={{ marginRight: "10px", cursor: "pointer"}} src={removeIconWhite} />
                          //       <span> Remover </span>
                          //     </div>
                          //     }
                          //   </label>
                          //   <input
                          //     id="filesType"
                          //     name="files"
                          //     style={{ opacity: 0, position: "fixed", position: "absolute", top: 0 }}
                          //     type="file"
                          //     onChange={ (e) => handleFile(e) }
                          //   />
                          // </div>
                          // </div>
                          // </div>
                          // </div>
                          <input
                            type="file"
                            name="files"
                            label={meta.label}
                            onChange={(e) => handleFile(e)}
                          />
                        )
                      : null}
                  </Box>
                );
              })}
            </Box>
            {!["ver"].includes(match.params.operation) && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: 10,
                    gap: "1rem",
                    marginLeft: "-20px",
                  }}
                >
                  <>
                    <Button
                      variant="outlined"
                      type="cancel"
                      onClick={(e) => {
                        e.preventDefault();
                        setAction(null);
                        history.goBack();
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button type="submit" variant="contained">
                      Guardar
                    </Button>
                  </>
                </Box>
              </Box>
            )}
          </Form>
        </Box>

        {!["ver", "editar", "mobilidade", "progresso"].includes(
          match.params.operation
        ) && (
          <Box sx={{ flexBasis: "35%" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              sx={{ marginBottom: "12px" }}
            >
              <Tab
                label="Quadros ativos"
                sx={{
                  fontSize: "12.5px",
                  backgroundColor: selectedTab == 0 ? "#f0f0f0" : "#fff",
                }}
              />
              <Tab
                label="Quadros não ativos"
                sx={{
                  fontSize: "12.5px",
                  backgroundColor: selectedTab == 1 ? "#f0f0f0" : "#fff",
                }}
              />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
              <SearchBar query={query} setQuery={setQuery} />
              <SideTable
                items={institutionEmployeesActive}
                displayColumns={sideTableHeaders}
                onRowClicked={onRowClicked}
                setSideTablePageIndex={setSideTablePageIndex}
                // institutionEmployeesTotalPages={institutionEmployeesTotalPages}
                institutionEmployeesTotalPages={institutionEmployeesTotalPages}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <SearchBar query={inactiveQuery} setQuery={setInactiveQuery} />
              <SideTable
                items={institutionEmployeesInactive}
                displayColumns={sideTableHeaders}
                onRowClicked={onRowClicked}
                setSideTablePageIndex={setInactivePageIndex}
                // institutionEmployeesTotalPages={institutionEmployeesTotalPages}
                institutionEmployeesTotalPages={totalPageInactive}
              />
            </TabPanel>
          </Box>
        )}
      </Box>
      <Modal
        open={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        aria-labelledby="modal-modal-info"
        aria-describedby="modal-modal-employee-info"
      >
        <ModalContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: "18px !important",
                  fontWeight: "bold",
                  textAlign: "center",

                  marginLeft: "auto",
                }}
              >
                Alocação de {clickedEmployee?.employee?.name}
              </Typography>
              <Close
                onClick={() => setShowInfoModal(false)}
                style={{
                  marginRight: "1rem",
                  marginLeft: "auto",
                  color: "red",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                margin: "0 2rem",
                flexDirection: "column",
                gap: "0.5rem",
                marginBottom: "2rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <Typography sx={{ fontWeight: "bold" }}>Nome: </Typography>{" "}
                {clickedEmployee?.employee?.name}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <Typography sx={{ fontWeight: "bold" }}>BI: </Typography>{" "}
                {clickedEmployee?.employee?.bi}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <Typography sx={{ fontWeight: "bold" }}>
                  Unidade Organica:{" "}
                </Typography>{" "}
                {clickedEmployee?.unit?.name}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <Typography sx={{ fontWeight: "bold" }}>
                  Cargo:{" "}
                </Typography>{" "}
                {clickedEmployee?.position?.name}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  É consultor:{" "}
                </Typography>{" "}
                {clickedEmployee?.consultant ? "Sim" : "Não"}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <Typography sx={{ fontWeight: "bold" }}>
                  Categoria:{" "}
                </Typography>{" "}
                {clickedEmployee?.category?.name}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Regime de contrato:{" "}
                </Typography>
                {clickedEmployee?.contractualRegimen?.name}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Vínculo / Relação Jurídica:{" "}
                </Typography>
                {clickedEmployee?.juridicalBond?.name}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Código Identificação Funcionário:{" "}
                </Typography>
                {clickedEmployee?.cifNumber}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <Typography sx={{ fontWeight: "bold" }}>
                  Início Funções:{" "}
                </Typography>{" "}
                {clickedEmployee?.startedOn}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "16px 16px",
                gap: "8px",
              }}
            >
              <Button
                variant="outlined"
                type="cancel"
                onClick={() => setShowInfoModal(false)}
              >
                Fechar
              </Button>
              <Button
                variant="contained"
                onClick={() => onAllocatedRowClicked(allocationItems.item)}
              >
                Nova alocação
              </Button>
            </Box>
          </Box>
        </ModalContainer>
      </Modal>
    </div>
  );
};

export default AlocacaoQuadrosForm;

export const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};
